import { Box, Center, Group, Loader, Stack, TextInput, Tooltip } from '@mantine/core'
import { IconCalendar } from '@tabler/icons-react'
import React from 'react'
import { useDocDetailState } from '~/client/components/doc-detail/state'
import { DocTypesSelect } from '~/client/components/doc-detail/term/doc-types-select'
import { AutofillComp, type AutofillCompProps } from '~/client/components/util/autofill-comp'
import { DatePickerInputUTC } from '~/client/components/util/date-picker-utc'
import { zIndex } from '~/client/components/z-index'
import { useCurrentCorpAuth } from '~/client/lib/hooks'
import { theme } from '~/client/lib/theme'
import type { AutocheckValue } from '~/common/autocheck'
import { AugmentedMetadataDate, docTypeStr } from '~/common/schema'
import type { ZDocAutocheckField } from '~/common/schema/autocheck'
import { ZParty } from '~/common/schema/party'

const modifiedInputColor = theme.colors.blue[6]
const warningInputColor = theme.colors.urgent[6]

const getInputStyles = ({
  isDirty,
  autocheckResult,
}: {
  isDirty: boolean
  autocheckResult: AutocheckValue
}) => {
  if (autocheckResult === 'warning')
    return {
      input: { borderColor: warningInputColor },
    }
  if (isDirty)
    return {
      input: { borderColor: modifiedInputColor },
    }
  return {}
}

interface WithAutofillProps extends Omit<AutofillCompProps, 'autocheckProps' | 'popoverPosition'> {
  hideIcon?: boolean
  isLoading?: boolean
  autocheckMetadataField: ZDocAutocheckField
}

const WithAutofill: React.FC<WithAutofillProps> = ({
  children,
  isLoading = false,
  hideIcon = false,
  valueIsFilled,
  autocheckMetadataField,
  ...props
}) => {
  const autocheckFieldResults = useDocDetailState((state) => state.autocheck.autocheckFieldResults)
  const docCryptId = useDocDetailState((state) => state.docCryptId)
  const autocheckResult = autocheckFieldResults[autocheckMetadataField]

  return (
    <Group align='flex-end' gap='xs' wrap='nowrap' justify='space-between'>
      <Box w='100%'>{children}</Box>
      {/* Hack to align icon with Input (because of extra space from title) */}
      <Center w={32} mb={4} style={hideIcon ? { opacity: 0, visibility: 'hidden' } : undefined}>
        {isLoading ? (
          <Loader size='xs' mb={4} />
        ) : (
          <AutofillComp
            valueIsFilled={valueIsFilled && autocheckResult === 'ok'}
            // docCryptId will always be defined because autocheck can only be computed after
            // the doc is uploaded to S3
            autocheckDismissProps={
              docCryptId && {
                type: 'DOCUMENT',
                docCryptId,
                metadataField: autocheckMetadataField,
              }
            }
            autocheckResult={autocheckResult}
            {...props}
            popoverPosition='end'
          />
        )}
      </Center>
    </Group>
  )
}

const CounterParty: React.FC = () => {
  const { data: auth } = useCurrentCorpAuth()
  const form = useDocDetailState((state) => state.form)
  const isSaving = useDocDetailState((state) => state.isSaving)
  const _isLoading = useDocDetailState((state) => state.isLoading)
  const autofill = useDocDetailState((state) => state.autofill)
  const suggestionsLoadingStates = useDocDetailState((state) => state.suggestionsLoadingStates)
  const autocheckFieldResults = useDocDetailState((state) => state.autocheck.autocheckFieldResults)
  const hasName = !!form.values.party.name
  const hasEmail = !!form.values.party.email
  const isLoading = _isLoading || isSaving
  const isInvestor = auth?.level === 'investor'

  return (
    <>
      <WithAutofill
        isLoading={suggestionsLoadingStates.parties}
        targetInputDisabled={isLoading}
        autofills={autofill?.parties.map((item) => ZParty.display(item))}
        onAutofill={(index) => {
          const party = autofill?.parties[index]
          form.setFieldValue('party.name', party?.name)
          if (!hasEmail) form.setFieldValue('party.email', party?.email)
        }}
        valueIsFilled={hasName}
        autocheckMetadataField='party'
      >
        <TextInput
          disabled={isLoading}
          readOnly={isInvestor}
          type='text'
          label='Counterparty'
          data-testid='counterparty-input'
          {...form.getInputProps('party.name')}
          styles={getInputStyles({
            isDirty: form.isDirty('party.name'),
            autocheckResult: autocheckFieldResults.party,
          })}
        />
      </WithAutofill>
      <WithAutofill
        testid='email-autofill'
        isLoading={suggestionsLoadingStates.emails}
        targetInputDisabled={isLoading}
        autofills={autofill?.emails}
        onAutofill={(index) => {
          const email = autofill?.emails[index]
          form.setFieldValue('party.email', email)
        }}
        valueIsFilled={hasEmail}
        autocheckMetadataField='email'
      >
        <TextInput
          disabled={isLoading}
          readOnly={isInvestor}
          type='email'
          label='Counterparty Email'
          data-testid='counterparty-email-input'
          {...form.getInputProps('party.email')}
          styles={getInputStyles({
            isDirty: form.isDirty('party.email'),
            autocheckResult: autocheckFieldResults.email,
          })}
        />
      </WithAutofill>
    </>
  )
}

export const PropertiesFields: React.FC = () => {
  const { data: auth } = useCurrentCorpAuth()
  const form = useDocDetailState((state) => state.form)
  const allowedTypes = useDocDetailState((state) => state.allowedTypes)
  const isSaving = useDocDetailState((state) => state.isSaving)
  const allowAllTypes = useDocDetailState((state) => state.allowAllTypes)
  const _isLoading = useDocDetailState((state) => state.isLoading)
  const autofill = useDocDetailState((state) => state.autofill)
  const suggestionsLoadingStates = useDocDetailState((state) => state.suggestionsLoadingStates)
  const isNewDoc = useDocDetailState((state) => state.isNewDoc)
  const preselectedType = useDocDetailState((state) => state.preselectedType)
  const autocheckFieldResults = useDocDetailState((state) => state.autocheck.autocheckFieldResults)
  const [filledType, setFilledType] = React.useState(false)
  const isInvestor = auth?.level === 'investor'
  const isLoading = _isLoading || isSaving

  return (
    <Tooltip.Floating disabled={!isSaving} label='Saving...' withinPortal={false}>
      <Stack gap='xs'>
        <WithAutofill
          testid='title-autofill'
          isLoading={suggestionsLoadingStates.titles}
          targetInputDisabled={isLoading}
          autofills={autofill?.titles}
          onAutofill={(index) => {
            form.setFieldValue('title', autofill?.titles[index])
          }}
          valueIsFilled={!!form.values.title}
          autocheckMetadataField='title'
        >
          <TextInput
            label='Document Title'
            data-testid='contract-title-input'
            disabled={isLoading}
            readOnly={isInvestor}
            styles={getInputStyles({
              isDirty: form.isDirty('title'),
              autocheckResult: autocheckFieldResults.title,
            })}
            {...form.getInputProps('title')}
          />
        </WithAutofill>
        <WithAutofill
          testid='type-autofill'
          isLoading={suggestionsLoadingStates.types}
          targetInputDisabled={isLoading}
          autofills={autofill?.types.map((item) => docTypeStr(item))}
          onAutofill={(index) => {
            const typeValue = autofill?.types[index]
            if (typeValue) {
              form.setFieldValue('type', typeValue)
              setFilledType(true)
            }
          }}
          valueIsFilled={
            // Show if the doc is on /processing or being uploaded to a relation/corp
            !(form.values.type === 'PROCESSING' || isNewDoc) ||
            // Do not show id there is only 1 type and it's already selected
            (autofill?.types.length === 1 && form.values.type === autofill.types[0]) ||
            // do not show when a type is pre-selected (e.g. missing doc upload)
            !!preselectedType ||
            filledType
          }
          autocheckMetadataField='type'
        >
          <DocTypesSelect
            label='Type'
            placeholder='None selected'
            disabled={isLoading}
            readOnly={isInvestor}
            required
            {...form.getInputProps('type')}
            data-testid='contract-type-select'
            allowedTypes={allowedTypes}
            allowAllTypes={allowAllTypes}
            styles={getInputStyles({
              isDirty: form.isDirty('type'),
              autocheckResult: autocheckFieldResults.type,
            })}
          />
        </WithAutofill>
        <CounterParty />
        <WithAutofill
          testid='startDate-autofill'
          isLoading={suggestionsLoadingStates.dates}
          targetInputDisabled={isLoading}
          autofills={autofill?.startDates.map((item) => AugmentedMetadataDate.display(item) ?? '')}
          onAutofill={(index) => {
            // we cannot set the value to `null` so we set it to `undefined` in that case
            form.setFieldValue('startDate', autofill?.startDates[index] ?? undefined)
          }}
          valueIsFilled={!!form.values.startDate}
          autocheckMetadataField='date'
        >
          <DatePickerInputUTC
            data-testid='doc-detail-startDate-input'
            label='Date'
            readOnly={isInvestor}
            firstDayOfWeek={0}
            allowDeselect={false}
            leftSection={<IconCalendar size={16} />}
            popoverProps={{ zIndex: zIndex.modal }}
            disabled={isLoading}
            {...form.getInputProps('startDate')}
            styles={getInputStyles({
              isDirty: form.isDirty('startDate'),
              autocheckResult: autocheckFieldResults.date,
            })}
          />
        </WithAutofill>
      </Stack>
    </Tooltip.Floating>
  )
}
