import { Badge, Box, Group, Table, Text } from '@mantine/core'
import * as React from 'react'
import { MiniDoc } from '~/client/components/util/doc'
import {
  InlineBooleanInput,
  InlineDateInput,
  InlineLawFirmSelect,
  InlineNumericInput,
  InlinePartyInput,
  InlineStateSelect,
  InlineStringInput,
} from '~/client/components/util/inline'
import type { BaseMetadataRowProps } from '~/client/components/util/metadata/util'
import { MetadataRowSkeleton } from '~/client/components/util/metadata/util'
import { metadataRowHoverClasses } from '~/client/lib/css-util.css'
import { theme } from '~/client/lib/theme'
import type { MetadataDisplayProps, ZDocType } from '~/common/schema/'
import {
  AugmentedMetadataNumber,
  type MetadataSchemaMap,
  type ZAugmentedDoc,
} from '~/common/schema/'
import {
  type AugmentedMetadata,
  AugmentedMetadataPercentage,
  AugmentedMetadataPrice2,
  AugmentedMetadataPrice4,
} from '~/common/schema/metadata'

export const DisplaySource: React.FC<{
  initialValue?: AugmentedMetadata
  docs?: ZAugmentedDoc[]
  supportedTypes?: ZDocType[]
}> = ({ initialValue, docs, supportedTypes }) => {
  if (!initialValue) return null
  const { type } = initialValue
  switch (type) {
    case 'document': {
      const doc = docs?.find((_doc) => _doc.cryptId.equals(initialValue.sourceCryptId))
      return doc ? (
        <MiniDoc
          data-testid='metadata-row-mini-doc'
          isInactive={false}
          doc={doc}
          supportedTypes={supportedTypes}
        />
      ) : null
    }
    case 'edited':
      if (initialValue.value === null) return null
      return <Badge data-testid='metadata-row-badge-edited'>Edited</Badge>
  }
}

type MetadataRowProps = {
  [K in keyof MetadataSchemaMap]: {
    displayProps: MetadataDisplayProps<K>
  }
}[keyof Omit<MetadataSchemaMap, 'aggregate' | 'docDate' | 'docString'>] &
  Omit<BaseMetadataRowProps, 'display'> & {
    size?: 'sm' | 'lg'
  }

export const MetadataRow = ({
  isLoading,
  alignment = 'start',
  size = 'lg',
  displayProps,
}: MetadataRowProps): JSX.Element => {
  const noWrap = size === 'lg'

  const { display, schema, initialValue, update, docs, autofillData } = displayProps
  const inlineEditor = React.useMemo(() => {
    switch (schema) {
      case 'price2':
        return (
          docs && (
            <InlineNumericInput
              metadataSchema={AugmentedMetadataPrice2}
              leftText='$'
              initialValue={initialValue}
              update={update}
              docs={docs}
              alignment={alignment}
              noWrap={noWrap}
            />
          )
        )
      case 'price4':
        return (
          docs && (
            <InlineNumericInput
              metadataSchema={AugmentedMetadataPrice4}
              leftText='$'
              initialValue={initialValue}
              update={update}
              docs={docs}
              alignment={alignment}
              noWrap={noWrap}
            />
          )
        )
      case 'percentage':
        return (
          docs && (
            <InlineNumericInput
              metadataSchema={AugmentedMetadataPercentage}
              initialValue={initialValue}
              update={update}
              docs={docs}
              alignment={alignment}
              noWrap={noWrap}
            />
          )
        )
      case 'string':
        return (
          docs && (
            <InlineStringInput
              initialValue={initialValue}
              update={update}
              docs={docs}
              alignment={alignment}
              noWrap={noWrap}
              autofillData={autofillData}
            />
          )
        )
      case 'date':
        return (
          docs && (
            <InlineDateInput
              initialValue={initialValue}
              update={update}
              docs={docs}
              alignment={alignment}
              autofillData={autofillData}
              noWrap={noWrap}
            />
          )
        )
      case 'number':
        return (
          docs && (
            <InlineNumericInput
              metadataSchema={AugmentedMetadataNumber}
              initialValue={initialValue}
              update={update}
              docs={docs}
              alignment={alignment}
              noWrap={noWrap}
              autofillData={autofillData}
            />
          )
        )
      case 'pool':
        return (
          docs && (
            <InlineBooleanInput
              initialValue={initialValue}
              update={update}
              docs={docs}
              alignment={alignment}
              noWrap={noWrap}
            />
          )
        )
      case 'party':
        return (
          <InlinePartyInput
            initialValue={initialValue}
            update={update}
            alignment={alignment}
            autofillData={autofillData}
            noWrap={noWrap}
          />
        )
      case 'state':
        return (
          <InlineStateSelect
            initialValue={initialValue}
            update={update}
            alignment={alignment}
            noWrap={noWrap}
          />
        )
      case 'lawFirm':
        return (
          <InlineLawFirmSelect
            initialValue={initialValue}
            update={update}
            alignment={alignment}
            noWrap={noWrap}
          />
        )
    }
  }, [autofillData, docs, initialValue, schema, update, alignment, noWrap])
  const noSource = schema === 'party' || schema === 'state' || schema === 'lawFirm'

  if (isLoading) {
    return <MetadataRowSkeleton display={display} alignment={alignment} noSource={noSource} />
  }

  if (size === 'sm')
    return (
      <Table.Tr className={metadataRowHoverClasses.hover}>
        <Table.Td>
          <Text size='sm' fw={700} lineClamp={1}>
            {display}
          </Text>
          <Group style={{ alignItems: 'start' }}>
            <Box style={{ flex: 1 }} fz='sm'>
              {inlineEditor}
            </Box>
            {schema !== 'party' && schema !== 'state' && schema !== 'lawFirm' && (
              <Box mt={2}>
                <DisplaySource
                  initialValue={initialValue}
                  docs={docs}
                  supportedTypes={displayProps.supportedTypes}
                />
              </Box>
            )}
          </Group>
        </Table.Td>
      </Table.Tr>
    )

  return (
    <Table.Tr className={metadataRowHoverClasses.hover}>
      <Table.Td style={{ maxWidth: theme.other.widths.xs }}>{display}</Table.Td>
      <Table.Td
        data-testid='metadata-field-value'
        colSpan={
          noSource
            ? 2
            : undefined /* this is to give more room for the party string (name + email can be pretty long) */
        }
      >
        <Box style={{ display: 'flex', justifyContent: alignment }}>{inlineEditor}</Box>
      </Table.Td>

      {/* Can't use noSource variable here because of type check */}
      {schema !== 'party' && schema !== 'state' && schema !== 'lawFirm' && (
        <Table.Td>
          <DisplaySource
            initialValue={initialValue}
            docs={docs}
            supportedTypes={displayProps.supportedTypes}
          />
        </Table.Td>
      )}
    </Table.Tr>
  )
}

export { ComputedMetadataRow } from './computed'
export { MiniObjectMetadataRow } from './mini-object'
